import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";

const PublicationPlain = ({ data, location }) => {
  const intl = useIntl();
  const post = data.contentfulClinicalPublicationPlain;
  const content_en = post.results.childMarkdownRemark.html
  const content_de = post.conclusions.childMarkdownRemark.html

  return (
    <Layout location={location} mode="beige">
      <Seo title={post.title && post.title} description />
      <div
        className="post mediaroom_post"
        style={{
          padding: "40px 20px",
          maxWidth: "740px",
          margin: "0 auto"
        }}
      >
        <h1
          style={{}}
        >{post.title && post.title}</h1>
        <div
          className="body-text"
          dangerouslySetInnerHTML={{
            __html: intl.locale === "en" ? content_en : content_de
          }}
        />
      </div>
    </Layout>
  );
};
export default PublicationPlain;

export const pageQuery = graphql`
  query ($slug: String, $language: String) {
    contentfulClinicalPublicationPlain: contentfulClinicalPublications(
      slug: { eq: $slug }
      node_locale: { eq: $language }
    ) {
      id
      title
      slug
      results {
        childMarkdownRemark{
          html
        }
      }
      conclusions {
        childMarkdownRemark{
          html
        }
      }
      publishDate(formatString: "DD. MMMM YYYY")
      publishYear: publishDate(formatString: "YYYY")
      updatedAt(formatString: "YYYY/MM/DD")
      createdAt(formatString: "YYYY/MM/DD")
    }
  }
`;
